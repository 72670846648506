<template>
  <button
    class="base-button"
    :class="[
      `base-button--${props.variant}`,
      `base-button--${props.size}`,
      `font--${props.font}`,
      { fill: props.fill },
    ]"
    v-bind="$attrs"
  >
    {{ props.text }}
  </button>
</template>

<script setup lang="ts">
interface Props {
  text?: string;
  variant?: 'prime' | 'ghost' | 'white-ghost' | 'dark' | 'danger';
  size?: 'sm' | 'sl' | 'sb' | 'round-sm';
  font?: 'b2--r' | 'b5--r' | 'b5--s' | 'h2';
  fill?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'prime',
  size: 'sm',
  font: 'b5--r',
  fill: false,
});
</script>

<style scoped lang="scss">
$borderWidth: 2px;
$transitionDuration: 0.3s;
$transition: background-color $transitionDuration;

@mixin invisible-border {
  border-color: transparent;
  border-style: solid;
  border-width: $borderWidth;
}

@mixin prime {
  color: var(--base-black);
  background-color: var(--main-yellow);
  transition: all 0.3s ease;
  padding: 0 25px;
  &:hover {
    transition: all 0.3s ease;
    text-shadow: 0.2px 0.2px 0 var(--base-black),
      -0.2px -0.2px 0 var(--base-black), 0.2px -0.2px 0 var(--base-black),
      -0.2px 0.2px 0 var(--base-black);
  }
}

@mixin ghost {
  color: var(--base-black);
  background-color: transparent;
  border: $borderWidth solid var(--base-black);
  transition: $transition;
  &:hover {
    color: var(--base-black);
    text-shadow: 0.2px 0.2px 0 var(--base-black),
      -0.2px -0.2px 0 var(--base-black), 0.2px -0.2px 0 var(--base-black),
      -0.2px 0.2px 0 var(--base-black);
    background-color: var(--main-yellow);
    border-color: var(--main-yellow);
  }
}

@mixin white-ghost {
  color: var(--base-white);
  background-color: transparent;
  border: $borderWidth solid var(--main-yellow);
  transition: all 0.3s ease;
  &:hover {
    transition: all 0.3s ease;
    color: var(--base-black);
    text-shadow: 0.2px 0.2px 0 var(--base-black),
      -0.2px -0.2px 0 var(--base-black), 0.2px -0.2px 0 var(--base-black),
      -0.2px 0.2px 0 var(--base-black);
    background-color: var(--main-yellow);
  }

  @media (max-width: 1000px) {
    &:hover {
      color: var(--base-white);
      text-shadow: 0.2px 0.2px 0 var(--base-black),
        -0.2px -0.2px 0 var(--base-black), 0.2px -0.2px 0 var(--base-black),
        -0.2px 0.2px 0 var(--base-black);
      background-color: transparent;
    }
  }
}

@mixin dark {
  color: var(--base-white);
  background-color: var(--base-black);
  border: $borderWidth solid var(--base-black);
  transition: $transition;
  &:hover {
    color: var(--base-black);
    text-shadow: 0.2px 0.2px 0 var(--base-black),
      -0.2px -0.2px 0 var(--base-black), 0.2px -0.2px 0 var(--base-black),
      -0.2px 0.2px 0 var(--base-black);
    background-color: var(--main-yellow);
    border-color: var(--main-yellow);
  }
}

@mixin danger {
  color: var(--base-white);
  background-color: var(--secondary-red-stroke);
  border: $borderWidth solid var(--secondary-red-stroke);
  transition: $transition;
}

.base-button {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 22px;
  border: none;
  outline: none;
  margin: 0;
  border-radius: 50px;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    background-color: var(--base-gray);
    border-color: var(--base-gray);
    color: var(--base-dark-gray);
    opacity: 1 !important;
    &:hover {
      background-color: var(--base-gray);
      border-color: var(--base-gray);
      color: var(--base-dark-gray);
      opacity: 1 !important;
      text-shadow: unset;
    }
  }

  &--prime {
    @include prime;
  }
  &--ghost {
    @include ghost;
  }
  &--white-ghost {
    @include white-ghost;
  }
  &--dark {
    @include dark();
  }
  &--danger {
    @include danger;
  }
  &--sm {
    height: 48px;
  }
  &--sl {
    height: 54px;
  }
  &--sb {
    height: 72px;
    padding: 0 32px;
  }
  &--round {
    height: 44px;
    padding: 13px;
    border-radius: 50%;
  }
  &--round-sm {
    padding: 4px;
    height: 24px;
  }

  &.fill {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .base-button {
    height: 60px;
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    &--sb {
      height: 48px;
    }
  }
}
</style>
