<template>
  <q-dialog
    ref="dialogRef"
    :maximized="createPopUp.$state.maximized"
    :position="createPopUp.$state.position"
    :backdrop-filter="createPopUp.$state.backdropFilter"
    style="z-index: 9999999"
  >
    <component
      :is="createPopUp.$state.currentPopUp"
      @hide_popup="closePopUp"
    />
  </q-dialog>
</template>

<script setup lang="ts">
import { PopUpServices } from "~/services/PopUp/callPopUp";
import { useDialogPluginComponent, QDialog } from "quasar";

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const createPopUp = PopUpServices();

const closePopUp = (state: "ok" | "ca") => {
  createPopUp.$state.currentPopUp = "";
  if (state === "ok") {
    onDialogOK();
  } else if (state === "ca") {
    onDialogCancel();
  } else {
    onDialogHide();
  }
};

onMounted(() => {
  createPopUp.$state.popUpRef = dialogRef.value;
});
</script>

<style lang="scss"></style>
