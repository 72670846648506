import { defineStore } from "pinia";
import { QDialog } from "quasar";
import { Dialog } from "quasar";

import PopUpComponent from "~/services/PopUp/PopUp.vue";
import MobileDownUpPopUp from "~/services/PopUp/MobileDownUpPopUp.vue";
import PopUpAuthComponent from "~/services/PopUp/AuthPopUp.vue";

type DialogPosition =
  | "standard"
  | "top"
  | "right"
  | "bottom"
  | "left"
  | undefined;

  type PopUpModalType =
  | typeof PopUpComponent
  | typeof PopUpAuthComponent
  | typeof MobileDownUpPopUp;

export const PopUpServices = defineStore("PopUpServices", {
  state: () => ({
    popUpRef: undefined as Ref<QDialog | undefined> | undefined,
    createPopUp: false,
    currentPopUp: "" as any,
    currentPopUpData: null as any,
    PopUpPersistent: false,
    position: "standart" as DialogPosition,
    maximized: undefined as boolean | undefined,
    backdropFilter: "",

  }),
  actions: {
    callPopUp(args: {
      componentName: any;
      data?: any;
      persistent?: boolean;
      position?: DialogPosition;
      maximized?: boolean;
      backdropFilter?: string;
      defaultPopupComponent?: PopUpModalType;
    }) {
      this.maximized = args.maximized;
      this.currentPopUp = args.componentName;
      this.currentPopUpData = args.data;
      this.createPopUp = true;
      this.position = args.position;
      this.backdropFilter = args.backdropFilter;
      return new Promise<boolean>((resolve) => {
        Dialog.create({
          component: args.defaultPopupComponent ? args.defaultPopupComponent : PopUpComponent,
          persistent: args.persistent,
          position: args.position,
          maximized: args.maximized,
          backdropFilter: args.backdropFilter,
          ok: {
            push: true,
          },
          cancel: {
            push: true,
          },
        })
          .onOk(() => {
            resolve(true);
          })
          .onCancel(() => {
            resolve(false);
          })
          .onDismiss(() => {
            resolve(false);
            this.maximized = false;
          });
      });
    },
  },
});
